<template>
    <div class="template">

    </div>
</template>
<script>
export default {
	data() {
		return{

		}
	},
	created(){

	},
	methods:{

	}
};
</script>
<style scoped>

</style>
